import routes from '@/store/modules/routes';

export default [
    {
        path: '/dados/arquivos-dados',
        component: () => import('@/views/Index'),
        children: [
            {
                name: routes.state.appRoutes['ARQUIVOS_DE_DADOS'],
                path: 'historico',
                component: () => import('@/views/dados/arquivos-dados/Index'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        { name: routes.state.appRoutes['DADOS'], path: '/dados' },
                        { name: 'Arquivos de Dados' }
                    ],
                },
            },
            {
                name: routes.state.appRoutes['ARQUIVOS_DE_DADOS_GRUPOS'],
                path: 'grupos/historico',
                component: () => import('@/views/dados/arquivos-dados/grupos-arquivos/Index'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        { name: routes.state.appRoutes['DADOS'], path: '/dados' },
                        { name: routes.state.appRoutes['ARQUIVOS_DE_DADOS'], path: '/dados/arquivos-dados/historico' },
                        { name: 'Grupos de Arquivos' }
                    ],
                },
            },
            {
                name: routes.state.appRoutes['ARQUIVOS_DE_DADOS_GRUPOS_ADICIONAR'],
                path: 'grupos/add',
                component: () => import('@/views/dados/arquivos-dados/grupos-arquivos/Edit'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        { name: routes.state.appRoutes['DADOS'], path: '/dados' },
                        { name: routes.state.appRoutes['ARQUIVOS_DE_DADOS'], path: '/dados/arquivos-dados/historico' },
                        { name: routes.state.appRoutes['ARQUIVOS_DE_DADOS_GRUPOS'], path: '/dados/arquivos-dados/grupos/historico' },
                        { name: 'Adicionar Grupo de Arquivos' }
                    ]
                }
            },
            {
                name: routes.state.appRoutes['ARQUIVOS_DE_DADOS_GRUPOS_EDITAR'],
                path: 'grupos/edit/:id',
                component: () => import('@/views/dados/arquivos-dados/grupos-arquivos/Edit'),
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        { name: routes.state.appRoutes['DADOS'], path: '/dados' },
                        { name: routes.state.appRoutes['ARQUIVOS_DE_DADOS'], path: '/dados/arquivos-dados/historico' },
                        { name: routes.state.appRoutes['ARQUIVOS_DE_DADOS_GRUPOS'], path: '/dados/arquivos-dados/grupos/historico' },
                        { name: 'Editar Grupo de Arquivos' }
                    ]
                }
            }
        ]
    },
]