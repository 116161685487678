import routes from '@/store/modules/routes';

export default [
  {
    path: '/geracao-bdgd/atualizacoes-mcpse',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['ATUALIZACOES_MCPSE'],
        path: 'historico',
        component: () =>
          import('@/views/geracao-bdgd/atualizacoes-tabelas/IndexMcpse.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { 
              name: routes.state.appRoutes['GERACAO_DA_BDGD'],
              path: '/geracao-bdgd' 
            },
            {
              name: routes.state.appRoutes['ATUALIZACOES_MCPSE'],
              path: '/geracao-bdgd/atualizacoes-mcpse/historico'
            },
            { name: 'Histórico' }
          ]
        },
      },
      {
        name: routes.state.appRoutes['ATUALIZACOES_MCPSE_VISUALIZAR'],
        path: 'visualizar/:tabela',
        component: () =>
          import('@/views/geracao-bdgd/atualizacoes-tabelas/Visualizar.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { 
              name: routes.state.appRoutes['GERACAO_DA_BDGD'],
              path: '/geracao-bdgd' 
            },
            {
              name: routes.state.appRoutes['ATUALIZACOES_MCPSE'],
              path: '/geracao-bdgd/atualizacoes-mcpse/historico'
            },
            {
              name: 'Visualizar Tabela',
            },
          ]
        },
      },
    ]
  }
];
